import React from 'react';

const LiquidationPopup = ({ onConfirm, onCancel, depositAmountUSD, currentHaircut, liquidationValue }) => {
  return (
    <div className="popup">
      <h2>Confirm Liquidation</h2>
      <p>Are you sure you want to liquidate your deposit?</p>
      <p>Deposit Amount: ${depositAmountUSD.toFixed(2)} USD</p>
      <p>Current Haircut: {currentHaircut.toFixed(2)}%</p>
      <p>Liquidation Value: ${liquidationValue.toFixed(2)} USD</p>
      <div className="popup-buttons">
        <button onClick={onConfirm}>Confirm</button>
        <button onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default LiquidationPopup;