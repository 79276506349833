import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

const APRCalculator = ({ apr }) => {
  return (
    <div className="apr-calculator">
      <h2>APR Information</h2>  
      <p>
        Current APR: {apr}% 
        <span className="tooltip">
          <FaQuestionCircle style={{marginLeft: '5px', cursor: 'help'}} />
          <span className="tooltiptext">Dynamic APR</span>
        </span>
      </p>
    </div>
  );
};

export default APRCalculator;