import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const LiquidateEquity = ({ onLiquidate, depositAmountUSD, lastDepositDate, remainingCooldownTime }) => {
  const calculateHaircut = () => {
    if (!lastDepositDate) return 25; // Default to max haircut if no deposit date

    const daysSinceDeposit = (Date.now() - new Date(lastDepositDate).getTime()) / (1000 * 60 * 60 * 24);
    const maxHaircut = 25;
    const minHaircut = 5;
    const haircutRange = maxHaircut - minHaircut;
    const daysToDecay = 365;

    const currentHaircut = maxHaircut - (haircutRange * Math.min(daysSinceDeposit, daysToDecay) / daysToDecay);
    return Math.max(currentHaircut, minHaircut);
  };

  const haircut = calculateHaircut();
  const liquidationValue = depositAmountUSD * (1 - haircut / 100);

  return (
    <div>
      <h2>Liquidate Equity</h2>
      <p>
        Current Haircut: {haircut.toFixed(2)}%
        <span 
          data-tooltip-id="haircut-tooltip"
          data-tooltip-content="Starts at 25% and decays to 5% over the period of 365 days"
          style={{marginLeft: '5px', cursor: 'help'}}
        >
          ?
        </span>
      </p>
      <Tooltip id="haircut-tooltip" />
      <p>Liquidation Value: ${liquidationValue.toFixed(2)}</p>
      <button onClick={onLiquidate} disabled={remainingCooldownTime > 0}>
        {remainingCooldownTime > 0
          ? `Cooldown: ${Math.floor(remainingCooldownTime / 3600)}h ${Math.floor((remainingCooldownTime % 3600) / 60)}m`
          : 'Liquidate'}
      </button>
    </div>
  );
};

export default LiquidateEquity;