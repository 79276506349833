import React from 'react';

function WalletConnection({ account, balance, onConnectWallet, network }) {
  return (
    <div className="wallet-connection">
      {account ? (
        <div>
          <p>Connected Account: {account}</p>
          <p>Balance: {parseFloat(balance).toFixed(4)} ETH</p>
          <p>Current Network: {network}</p>
        </div>
      ) : (
        <button onClick={onConnectWallet}>Connect Wallet</button>
      )}
    </div>
  );
}

export default WalletConnection;