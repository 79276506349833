import React from 'react';

const TransactionHistory = ({ history }) => {
  return (
    <div>
      <h2>Transaction History</h2>
      <ul>
        {history.map((tx, index) => (
          <li key={index}>
            {tx.date}: {tx.type} ${tx.amount} USD {tx.type === 'Deposited' ? 'to' : 'from'} {tx.address}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TransactionHistory;