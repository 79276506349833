import React from 'react';
import './TermsModal.css';

const TermsModal = ({ isOpen, onAccept }) => {
  if (!isOpen) return null;

  return (
    <div className="terms-modal-overlay">
      <div className="terms-modal">
        <button className="close-button" onClick={onAccept}>&times;</button>
        <h2>Terms & Conditions</h2>
        <div className="terms-content">
          <p>Use of this site and its associated services is subject to the following terms and conditions:</p>

          <p>Feedom is an experimental protocol, and I understand the risks associated with using the protocol and its associated functions.</p>

          <p>I confirm that I am familiar with the concepts of decentralized finance, and I have read and understand the <a href="https://apestar.gitbook.io/untitled" target="_blank" rel="noopener noreferrer">Feedom Documentation</a>.</p>

          <p>I understand that my interaction with Feedom (including its website, smart contracts, or any related functions) may place my tokens at-risk, and I hereby release Feedom, its contributors, and affiliated service providers from any and all liability associated with my use of Feedom.</p>

          <p>I am lawfully permitted to access this site and my use of and interaction with Feedom is not in contravention of any laws governing my jurisdiction of residence or citizenship.</p>
        </div>
        <button className="accept-button" onClick={onAccept}>I Accept</button>
      </div>
    </div>
  );
};

export default TermsModal;