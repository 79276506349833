import React, { useRef } from 'react';
import Draggable from 'react-draggable';

const DarkModeToggle = ({ isDarkMode, toggleDarkMode }) => {
  const nodeRef = useRef(null);

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleDarkMode();
  };

  return (
    <Draggable bounds="parent" nodeRef={nodeRef}>
      <div ref={nodeRef} className="dark-mode-toggle-container">
        <button 
          className="dark-mode-toggle" 
          onClick={handleToggle}
          onTouchEnd={handleToggle}
          aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          <div className="toggle-switch">
            {isDarkMode ? '☀️' : '🌙'}
          </div>
        </button>
      </div>
    </Draggable>
  );
};

export default DarkModeToggle;