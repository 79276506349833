import React from 'react';

const EarningsDisplay = ({ earningsUSD, depositAmountUSD, apr, onClaimRewards }) => {
  return (
    <div>
      <h2>Earnings</h2>
      <p>Current Deposit: ${depositAmountUSD.toFixed(2)} USD</p>
      <p>Current APR: {apr}%</p>
      <p>Current Earnings: ${earningsUSD.toFixed(2)} USD</p>
      <button onClick={onClaimRewards} disabled={earningsUSD <= 0}>
        Claim Rewards
      </button>
    </div>
  );
};

export default EarningsDisplay;