import React, { useState, useEffect } from 'react';

const DepositForm = ({ onDeposit, web3, account, network, ethPrice }) => {
  const [amount, setAmount] = useState('');
  const [usdEquivalent, setUsdEquivalent] = useState('0');

  useEffect(() => {
    if (amount && !isNaN(amount) && ethPrice) {
      setUsdEquivalent((parseFloat(amount) * ethPrice).toFixed(2));
    } else {
      setUsdEquivalent('0');
    }
  }, [amount, ethPrice]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
      alert('Please enter a valid amount');
      return;
    }
    onDeposit(amount);
    setAmount('');
  };

  return (
    <div className="deposit-form">
      <h2>Deposit ETH</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount in ETH"
          step="0.01"
          min="0"
          required
        />
        <p>USD Equivalent: ${usdEquivalent}</p>
        <button type="submit" disabled={!web3 || !account}>
          Deposit
        </button>
      </form>
      <p>Network: {network}</p>
    </div>
  );
};

export default DepositForm;